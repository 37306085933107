<script>
export default {
    name: "document2024",
    props: {
        info: Object
    }
}
</script>

<template>
    <div class="agreement-container print-page" v-if="info">
        <h1>2025年度安全生产责任书</h1>
        <p style="text-indent: 2em">
            为贯彻落实“安全第一，预防为主，综合治理”的安全方针，全力做好安全生产工作，依据安全生产相关法律法规、双方在司机端上业已达成的相关协议，特达成900游网约车驾驶员《2025年度安全生产责任书》以资遵守。
        </p>
        <h2>一、网约车驾驶员的安全生产责任</h2>
        <p>
            1.遵守《安全生产法》、《道路交通安全法》、《治安管理处罚法》等法律法规和本平台各项接单运输规则，自觉文明驾驶车辆，对乘客生命、财产安全负责，对驾驶安全负直接责任。</p>
        <p>
            2.驾驶员应确保接单时所驾驶车辆系在本平台注册，符合机动车年检要求，投保了包括但不限于交强险、足额的商业三者险、车上人员险等相关保险，并保持前述保险的持续有效，定期对车辆进行保养，经常检查车辆的主要机件，确保车辆正常行驶。</p>
        <p>
            3.驾驶员应确保在接单运输过程中人车一致，不得将本人在平台注册车辆交给他人驾驶接单，不得驾驶非本平台注册车辆接单，不得绕过平台派单擅自与平台乘客达成线下运输协议。</p>
        <p>
            4.接单运输过程中应严格遵守驾驶员安全行车操作规程，防范疲劳驾驶，杜绝不良嗜好，不带病开车，严禁发生酒后驾车、闯红灯、超速行驶、超员载客，以及使用毒品、服用影响安全驾驶的药物等违法行为。</p>
        <p>
            5.定期参加平台及所属公司组织的各类安全会议和培训并接受考核，有效掌握防御性驾驶、急救常识等安全与应急处置知识，自觉提升防御性驾驶意识与技能，主动辨识各类事故风险的危险源，自觉履行隐患排查义务和责任，及时回馈隐患排查结果。</p>
        <p>
            6.行车前做好车辆安全技术检查，保证车辆技术状况良好，包括轮胎、制动、转向、悬架、灯光与信号装置、应急设施及安全设备等，车载灭火器压力正常且在检定有效期内，按规定做好车辆日常检查工作。</p>
        <p>
            7.行车前要确保驾驶员自身和所有乘客必须系好安全带后再起步，驾驶员应主动提醒所有乘客系好安全带。</p>
        <p>8.不穿拖鞋、高跟鞋驾驶车辆，不发生吸烟、饮食、接打手机语音通话等妨碍安全驾驶的行为。</p>
        <p>
            9.乘客下车前应提示客人注意后方来人来车，并保持左侧后车门落锁状态。乘客贴近车辆时，防范轧脚，关闭后备箱盖时，防范轧手、轧头。</p>
        <p>
            10.要提前辨识恶劣天气、危险路段、危险时段、相关药物对安全驾驶的影响，采取有效措施予以规避，谨慎驾驶杜绝侥幸心理，连续驾驶4小时须保证20分钟的停车休息间隔。</p>
        <p>
            11.订单中一旦发生人伤、人亡交通事故，驾驶员应第一时间报警并组织抢救，及时向平台客服报备，配合后续平台或所属企业安全管理人员对事故的善后处理工作，严禁发生肇事逃逸、隐匿事故、破坏现场。</p>
        <p>
            12.遵守国家相关法规，平台安全规范服务，不与乘客发生肢体冲突，不对乘客进行各种形式威胁及骚扰，不发生开“斗气车”等危险驾驶行为。一经发现报公安机关处理。</p>
        <h2>二、交通安全、消防及机电安全、治安保卫等安全生产目标</h2>
        <h3>（一）交通安全控制指标：</h3>
        <p>无群死群伤事故，无同等(含)以上责任亡人事故。<br/>
            严禁发生肇事逃逸、迟报、谎报以及隐瞒不报事故；杜绝新闻媒体曝光。。<br/>
            不得出现12分交通违法情况。。<br/>
            定期参加平台及所属公司组织的培训及会议，并接受考核。</p>
        <h3>（二）消防及机电安全控制指标：</h3>
        <p>坚持各项安全生产操作规程，杜绝车辆火灾事故，特别是新能源车辆充换电过程中防范火灾的发生。</p>
        <p>严格排查车辆各部机件及灭火器的安全生产隐患，整改及报告率达100%。</p>
        <h3>（三）治安保卫目标控制指标：</h3>
        <p>杜绝重、特大责任治安事件，不造谣传谣，不参与群体性上访闹访、围堵政府机构事件。<br/>

            不发生泄密事件，不发生危害国家安全事件。<br/>

            杜绝对乘客进行各种类型的骚扰、威胁及危害人身安全的行为。<br/>

            定期参加平台及所属公司组织的培训及会议，并接受考核。</p>
        <h2>三、问责与追究</h2>
        <p>
            平台依据《安全生产法》等法律法规赋予的职责，根据双方在司机端上及其它场合达成的约定，对各类责任事故、治安事件进行问责，发生一起问责一起。</p>
        <h2>四、附则</h2>
        <p>
            1、本责任书对承接900游平台派单的网约车驾驶员在接单运输期间均构成责任约束，如还承接其它网约车平台订单或从事其它运输应从其规定。</p>
        <p>2、本责任书自签订之日起生效。</p>
        <p>3、本责任书有效期截止时间为2025年12月31日。</p>
        <p>4、若超过有效期在未签署新的责任书之前，本责任书依然有效。</p>
        <p>5、如遇特殊时期及事项，另行签署专项安全生产责任书。</p>
        <div style="display: flex;">
            <div>
                <p>网约车驾驶员：</p>
            </div>
            <img style="max-width:100%;height:50px;margin-top: -10px" :src="info.signaturePhotoUrl" alt="">
        </div>
        <p>签署日期：{{ info.safeSignatureTime | dateCus('yyyy年MM月dd日') }}</p>
        <p>（请本人用正楷、工整书写）</p>
    </div>
</template>

<style scoped lang="scss">
.agreement-container {
    padding: 30px 16px;
    background-color: #fff;
    color: #333333;
    line-height: 24px;
    font-size: 14px;

    h1 {
        font-weight: 600;
        font-size: 17px;
        margin-bottom: 20px;
        text-align: center;
    }

    p, h2, h3 {
        margin-bottom: 20px;
    }
}

.print-page {
    width: 210mm;
}

</style>
