<style lang="scss" scoped>
.eTrip-section-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;

  .print-item:not(:last-child) {
    margin-bottom: 15px;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
  .page-break-after {
    display: block;
    page-break-after: always;
  }
  .page-break-inside {
    display: block;
    page-break-inside: avoid;
  }
}
</style>

<template>
  <div class="print-container">
    <el-main class="com-eTrip-section section-has-footer">
      <div class="eTrip-section-cont">
        <div id="print-content" v-loading="loading">
          <div v-for="(vm,index) in signature" :key="index" class="print-item page-break">
            <document2024 v-if="['2024','2025'].includes(vm.year)" :info="vm"></document2024>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer class="com-eTrip-footer" height="64px">
      <div class="eTrip-footer-cont">
        <el-button type="primary" :disabled="loading" :loading="loading" style="float: right;margin-right: 20px"
                   v-print="printContent">
          打印 x{{ signature.length }}
        </el-button>
      </div>
    </el-footer>
  </div>

</template>
<script type="text/ecmascript-6">
import Document2024 from "@/www/pages/driverManage/safeAgreement/document2025.vue";
import moment from "moment";

export default {
  data() {
    return {
      signature: [],
      loading: false,
      printContent: {
        id: '#print-content',
        url: '',
        preview: false, // 预览工具是否启用
        previewTitle: "900",
        popTitle: '900'
      }
    }
  },
  components: {
    Document2024
  },
  mounted() {
    this.signature = this.sessionStorage.getObject('safe_agreement_signature').map((item) => ({
      ...item,
      year: moment(item.safeSignatureTime).format('YYYY')
    }))
    window.addEventListener('message', this.handleMessage, false);
    this.loading = false;
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage)
  },
  methods: {
    handleMessage(e) {
      console.log(e.data);
    }
  }
}
</script>

